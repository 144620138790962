/**
 * 参数解析
 * @param isGW boolen 是否国网
 * @param irgU8Array [] irg
 * @param irU8Array [] ir
 * @param dc8Array [] dc
 * @param fileName String 选中的文件名称
 * @param isPX1 boolen 是否PX1
 */
export function getParams({ isGW, irgU8Array, irU8Array, dcU8Array, fileName, isPX1 }) {
  const params = { fileName }
  let roiBuffer = []
  if (isGW) {
    if (dcU8Array) {
      // gw
      params.isGW = isGW
      params.irU8Array = irU8Array
      params.dcU8Array = dcU8Array

      // 找末尾标识
      const endList = [0x37, 0x66, 0x07, 0x1a, 0x12, 0x3a, 0x4c, 0x9f, 0xa9, 0x5d, 0x21, 0xd2, 0xda, 0x7d, 0x26, 0xbc]
      let endNum = 0
      for (let j = irU8Array.length - 16; j > 0; j--) {
        let bFind = true
        // 比较
        for (let i = 0; i < 16; i++) {
          if (irU8Array[j + i] == endList[i]) {
            continue
          } else {
            bFind = false
            break
          }
        }
        if (bFind) {
          endNum = j
          break
        }
      }

      // irList后16位否为末尾标识
      if (endNum === irU8Array.length - 16) {
        // dc格式
        // IR.jpg = 红外截图数据 + 国网数据(包含温度数据) + 红外数据的起始偏移地址 + 文件末尾标识
        // DC.jpg = 可见光截图数据(末尾标识0xff、0xd9) + 自定义头(128/256字节) + 红外灰度数据(width * height) + 音频(可能有) + 测温区域(可能有, 总长1024字节, 开始标识0xab、0xcd、0xef、0xff，结束标识0xff、0xfe、0xdc、0xba)
        console.log('dc')
        // 若是，则从dcList里取参数
        // 找jpg结尾位置 ff d9
        let offset = 0
        for (let i = 0; i < dcU8Array.length; i++) {
          if (dcU8Array[i] === 0xff && dcU8Array[i + 1] === 0xd9) {
            offset = i + 2
            break
          }
        }

        // gwDC 取参数
        const bMid = [dcU8Array[offset + 0], dcU8Array[offset + 1]]
        const cProType = (bMid[0].toString(16) + bMid[1].toString(16)).toLocaleUpperCase()
        const sSerialName = translateProName(cProType)

        // 产品型号
        params.cProType = cProType
        params.sSerialName = sSerialName

        // 自定义头大小
        const header = new Uint16Array(new Uint8Array([dcU8Array[offset + 2], dcU8Array[offset + 3]]).buffer)[0]

        // 环境信息
        const m_iValue = []
        for (let i = 0; i < 10; i++) {
          const val = []
          for (let j = 0; j < 4; j++) {
            val.push(dcU8Array[offset + 30 + 4 * i + j])
          }
          m_iValue.push(uint8Touint32(val))
        }
        // 发射率
        params.fEmiss = m_iValue[0] / 10000.0
        // 反射温度
        params.fReflectTemp = (cProType === 'CAAC' ? m_iValue[1] + 1500 : m_iValue[1]) / 10000.0
        // 大气温度
        params.fAirTemp = (cProType === 'CAAC' ? m_iValue[2] + 1500 : m_iValue[2]) / 10000.0
        // 距离
        params.fDistance = m_iValue[3] / 10000.0
        // 大气透过率
        params.fHumidity = m_iValue[5] / 10000.0
        // 距离修正系数K0
        params.k0 = m_iValue[6] / 10000.0
        // 距离修正系数B0
        params.b0 = m_iValue[7] / 10000.0
        // 距离修正系数K1
        params.k1 = m_iValue[8] / 10000.0
        // 距离修正系数B1
        params.b1 = m_iValue[9] / 10000.0

        // 色板编号
        params.colorIndex = dcU8Array[offset + 70]
        // 当前图像模式
        params.imgModel = dcU8Array[offset + 72]

        // 计算ir的偏离量
        const iOffset = jsRead(irU8Array, irU8Array.length - 20, 4)
        // 宽度
        params.iwidth = jsRead(irU8Array, iOffset + 2, 2)
        // 高度
        params.iheight = jsRead(irU8Array, iOffset + 4, 2)

        // 国网文件产品型号
        const typeBuffer = irU8Array.slice(
          iOffset + 20 + params.iwidth * params.iheight * 4 + 50,
          iOffset + 20 + params.iwidth * params.iheight * 4 + 50 + 32
        )
        params.gwType = ''
        for (const byte of typeBuffer) {
          if (byte !== 0) {
            params.gwType += String.fromCharCode(byte)
          }
        }

        if (params.gwType === 'PX1') {
          params.fReflectTemp += 273.15
          params.fAirTemp += 273.15
        }

        // 红外
        if (params.gwType === 'PX1') {
          // 红外数据面阵大小
          // 高度
          params.irHeight = uint8Touint32([dcU8Array[offset + 8], dcU8Array[offset + 9]])
          // 宽度
          params.irWidth = uint8Touint32([dcU8Array[offset + 10], dcU8Array[offset + 11]])
        }

        // 灰度图
        params.mIRBuffer = []
        for (let i = offset + header; i < offset + header + params.iheight * params.iwidth; i++) {
          params.mIRBuffer.push(dcU8Array[i])
        }

        // 可见光
        params.lightBuffer = []
        for (let i = 0; i < offset; i++) {
          params.lightBuffer.push(dcU8Array[i])
        }

        // 温度
        const pTempBuffer = []
        // 红外温度数据的起始偏移地址
        for (let i = iOffset + 20; i < iOffset + 20 + params.iheight * params.iwidth * 4; i++) {
          pTempBuffer.push(irU8Array[i])
        }

        if (pTempBuffer.length > 0) {
          let maxTemp = 0
          let minTemp = 0
          const pTempBufferDst = []
          // 4合一 转32进制  摄氏度
          for (let i = 0; i < pTempBuffer.length; i += 4) {
            const temp = (hex2float(jsRead(pTempBuffer, i, 4)) + 273.15) * 10 // 为防止失精度将摄氏度转为开尔文
            pTempBufferDst.push(temp)

            if (i === 0) {
              maxTemp = temp
              minTemp = temp
            } else if (temp > maxTemp) {
              maxTemp = temp
            } else if (temp < minTemp) {
              minTemp = temp
            }
          }
          params.pTempBufferDst = pTempBufferDst
          params.maxTemp = (maxTemp / 10 - 273.15).toFixed(1)
          params.minTemp = (minTemp / 10 - 273.15).toFixed(1)
        }

        // 音频 测温区域
        offset = offset + header + params.iheight * params.iwidth
        let iAudioLen = 0
        let roiFlag = false
        if (dcU8Array.length > offset) {
          for (let m = offset; m < dcU8Array.length - 4; m++) {
            if (dcU8Array[m] === 0xab && dcU8Array[m + 1] === 0xcd && dcU8Array[m + 2] === 0xef && dcU8Array[m + 3] === 0xff) {
              roiFlag = true
              break
            }
            iAudioLen++
          }
        }

        if (roiFlag) {
          // 有测温区域
          if (iAudioLen) {
            // 有音频和测温区域

            // 音频结束下标
            const audioEnd = offset + iAudioLen
            params.pAudioBuffer = dcU8Array.slice(offset, audioEnd)

            // 测温区域
            roiBuffer = dcU8Array.slice(audioEnd)
          } else {
            // 只有测温区域
            roiBuffer = dcU8Array.slice(offset)
          }
        } else {
          // 只有音频
          params.pAudioBuffer = dcU8Array.slice(offset)
        }
      } else {
        // ir格式
        // IR.jpg = 红外截图数据 + 国网数据(包含温度数据) + 红外数据的起始偏移地址 + 文件末尾标识 + 自定义头(128/256字节) + 红外灰度数据(width * height) + 音频(可能有) + 测温区域(可能有, 总长1024字节, 开始标识0xab、0xcd、0xef、0xff，结束标识0xff、0xfe、0xdc、0xba)
        // DC.jpg = 可见光截图数据(末尾标识0xff、0xd9)
        console.log('ir')
        // 不是，则从irList取参数
        // 红外数据的起始偏移地址
        const iOffset = jsRead(irU8Array, endNum - 4, 4)
        // 宽度
        params.iwidth = jsRead(irU8Array, iOffset + 2, 2)
        // 高度
        params.iheight = jsRead(irU8Array, iOffset + 4, 2)
        // 红外温度值点阵数据长度
        const tempLength = params.iwidth * params.iheight * 4
        // 发射率
        params.fEmiss = hex2float(jsRead(irU8Array, iOffset + tempLength + 20, 4))
        // 大气温度
        params.fAirTemp = hex2float(jsRead(irU8Array, iOffset + tempLength + 20 + 4, 4)) + 273.15
        // 反射温度
        params.fReflectTemp = hex2float(jsRead(irU8Array, iOffset + tempLength + 20 + 14, 4)) + 273.15
        // 距离
        params.fDistance = jsRead(irU8Array, iOffset + tempLength + 20 + 9, 4)
        // 产品型号
        const bMid = [irU8Array[endNum + 16], irU8Array[endNum + 16 + 1]]
        const cProType = (bMid[0].toString(16) + bMid[1].toString(16)).toLocaleUpperCase()
        const sSerialName = translateProName(cProType)
        params.cProType = cProType
        params.sSerialName = sSerialName
        // 自定义头大小
        const header = new Uint16Array(new Uint8Array([irU8Array[endNum + 16 + 2], irU8Array[endNum + 16 + 3]]).buffer)[0]
        // 大气透过率
        params.fHumidity = jsRead(irU8Array, endNum + 16 + 50, 4) / 10000.0
        // 距离修正系数K0
        params.k0 = jsRead(irU8Array, endNum + 16 + 54, 4) / 10000.0
        // 距离修正系数B0
        params.b0 = jsRead(irU8Array, endNum + 16 + 58, 4) / 10000.0
        // 距离修正系数K1
        params.k1 = jsRead(irU8Array, endNum + 16 + 62, 4) / 10000.0
        // 距离修正系数B1
        params.b1 = jsRead(irU8Array, endNum + 16 + 66, 4) / 10000.0

        // 色板编号
        params.colorIndex = irU8Array[endNum + 16 + 70]
        // 当前图像模式
        params.imgModel = irU8Array[endNum + 16 + 72]

        // 红外
        // 灰度图
        params.mIRBuffer = []
        for (let i = endNum + 16 + header; i < endNum + 16 + header + params.iheight * params.iwidth; i++) {
          params.mIRBuffer.push(irU8Array[i])
        }

        // 可见光
        params.lightBuffer = dcU8Array

        // 温度
        const pTempBuffer = []
        // 红外数据的起始偏移地址
        for (let i = iOffset + 20; i < iOffset + 20 + params.iheight * params.iwidth * 4; i++) {
          pTempBuffer.push(irU8Array[i])
        }

        if (pTempBuffer.length > 0) {
          let maxTemp = 0
          let minTemp = 0
          const pTempBufferDst = []
          // 4合一 转32进制  摄氏度
          for (let i = 0; i < pTempBuffer.length; i += 4) {
            const temp = (hex2float(jsRead(pTempBuffer, i, 4)) + 273.15) * 10 // 为防止失精度将摄氏度转为开尔文
            pTempBufferDst.push(temp)

            if (i === 0) {
              maxTemp = temp
              minTemp = temp
            } else if (temp > maxTemp) {
              maxTemp = temp
            } else if (temp < minTemp) {
              minTemp = temp
            }
          }
          params.pTempBufferDst = pTempBufferDst
          params.maxTemp = (maxTemp / 10 - 273.15).toFixed(1)
          params.minTemp = (minTemp / 10 - 273.15).toFixed(1)
        }

        // 音频 测温区域
        endNum = endNum + 16 + header + params.iheight * params.iwidth
        let iAudioLen = 0
        let roiFlag = false
        if (irU8Array.length > endNum) {
          for (let m = endNum; m < irU8Array.length - 4; m++) {
            if (irU8Array[m] === 0xab && irU8Array[m + 1] === 0xcd && irU8Array[m + 2] === 0xef && irU8Array[m + 3] === 0xff) {
              roiFlag = true
              break
            }
            iAudioLen++
          }
        }

        if (roiFlag) {
          // 有测温区域
          if (iAudioLen) {
            // 有音频和测温区域

            // 音频结束下标
            const audioEnd = endNum + iAudioLen
            params.pAudioBuffer = irU8Array.slice(endNum, audioEnd)

            // 测温区域
            roiBuffer = irU8Array.slice(audioEnd)
          } else {
            // 只有测温区域
            roiBuffer = irU8Array.slice(endNum)
          }
        } else {
          // 只有音频
          params.pAudioBuffer = irU8Array.slice(endNum)
        }
      }
    } else {
      // 判断是否是PX1
      const iOffset = jsRead(irU8Array, irU8Array.length - 20, 4)
      console.log('iriOffset', iOffset)
      // 宽度
      params.iwidth = jsRead(irU8Array, iOffset + 2, 2)
      // 高度
      params.iheight = jsRead(irU8Array, iOffset + 4, 2)
      // 产品型号
      const typeBuffer = irU8Array.slice(
        iOffset + 20 + params.iwidth * params.iheight * 4 + 50,
        iOffset + 20 + params.iwidth * params.iheight * 4 + 50 + 32
      )
      params.gwType = ''
      for (const byte of typeBuffer) {
        if (byte !== 0) {
          params.gwType += String.fromCharCode(byte)
        }
      }
      console.log('params.gwType', params.gwType)
      if (params.gwType === 'PX1') {
        getParamsByPX1(irU8Array, params)
      }
    }
  } else if (isPX1) {
    // PX1格式
    getParamsByPX1(irU8Array, params)
  } else {
    // irg格式
    // .irg = 自定义头(128/256字节) + 红外灰度数据(width * height) + 温度数据(width * height * 2) + 可见光数据(末尾标识0xff、0xd9) + 音频(可能有) + 测温区域(可能有, 总长1024字节, 开始标识0xab、0xcd、0xef、0xff，结束标识0xff、0xfe、0xdc、0xba)
    // .jpg 从irg取数据
    console.log('irg')
    // irg
    params.isGW = isGW
    params.irgU8Array = irgU8Array

    const bMid = [irgU8Array[0], irgU8Array[1]]
    const cProType = (bMid[0].toString(16) + bMid[1].toString(16)).toLocaleUpperCase()
    const sSerialName = translateProName(cProType)

    // 产品型号
    params.cProType = cProType
    params.sSerialName = sSerialName
    // 自定义头大小
    const header = new Uint16Array(new Uint8Array([irgU8Array[2], irgU8Array[3]]).buffer)[0]
    // 环境信息
    const m_iValue = []
    for (let i = 0; i < 10; i++) {
      const val = []
      for (let j = 0; j < 4; j++) {
        val.push(irgU8Array[30 + 4 * i + j])
      }
      m_iValue.push(uint8Touint32(val))
    }
    // 发射率
    params.fEmiss = m_iValue[0] / 10000.0
    // 反射温度
    params.fReflectTemp = (cProType === 'CAAC' ? m_iValue[1] + 1500 : m_iValue[1]) / 10000.0
    // 大气温度
    params.fAirTemp = (cProType === 'CAAC' ? m_iValue[2] + 1500 : m_iValue[2]) / 10000.0
    // 距离
    params.fDistance = m_iValue[3] / 10000.0
    // 大气透过率
    params.fHumidity = m_iValue[5] / 10000.0
    // 距离修正系数K0
    params.k0 = m_iValue[6] / 10000.0
    // 距离修正系数B0
    params.b0 = m_iValue[7] / 10000.0
    // 距离修正系数K1
    params.k1 = m_iValue[8] / 10000.0
    // 距离修正系数B1
    params.b1 = m_iValue[9] / 10000.0
    // 高度
    params.iheight = uint8Touint32([irgU8Array[8], irgU8Array[9]])
    // 宽度
    params.iwidth = uint8Touint32([irgU8Array[10], irgU8Array[11]])

    // 色板编号
    params.colorIndex = irgU8Array[70]
    // 当前图像模式
    params.imgModel = irgU8Array[72]

    // 红外
    // 灰度图
    params.mIRBuffer = []
    for (let i = header; i < params.iheight * params.iwidth + header; i++) {
      params.mIRBuffer.push(irgU8Array[i])
    }

    // 可见光
    const lightBuffer = []
    let haveLight = false
    let iOffset = header + params.iheight * params.iwidth * 3

    for (let i = iOffset; i < irgU8Array.length; i++) {
      lightBuffer.push(irgU8Array[i])
      if (irgU8Array[i] === 0xff && irgU8Array[i + 1] === 0xd9) {
        lightBuffer.push(irgU8Array[i + 1])
        haveLight = true
        break
      }
    }
    // 只有手持有可见光
    params.lightBuffer = haveLight ? lightBuffer : []

    // 温度
    const pTempBuffer = []
    for (let i = header + params.iheight * params.iwidth; i < header + params.iheight * params.iwidth * 3; i++) {
      pTempBuffer.push(irgU8Array[i])
    }

    if (pTempBuffer.length > 0) {
      let maxTemp = 0
      let minTemp = 0
      const pTempBufferDst = []
      // 2合一 转16进制 = 开尔文温度 * 10
      if (params.cProType === 'CAAC') {
        for (let i = 0; i < pTempBuffer.length; i += 2) {
          const temp = (uint8Touint32([pTempBuffer[i], pTempBuffer[i + 1]]) / 16) * 10
          pTempBufferDst.push(temp)

          if (i === 0) {
            maxTemp = temp
            minTemp = temp
          } else if (temp > maxTemp) {
            maxTemp = temp
          } else if (temp < minTemp) {
            minTemp = temp
          }
        }
      } else {
        for (let i = 0; i < pTempBuffer.length; i += 2) {
          const temp = uint8Touint32([pTempBuffer[i], pTempBuffer[i + 1]])
          pTempBufferDst.push(temp)

          if (i === 0) {
            maxTemp = temp
            minTemp = temp
          } else if (temp > maxTemp) {
            maxTemp = temp
          } else if (temp < minTemp) {
            minTemp = temp
          }
        }
      }

      params.pTempBufferDst = pTempBufferDst
      params.maxTemp = (maxTemp / 10 - 273.15).toFixed(1)
      params.minTemp = (minTemp / 10 - 273.15).toFixed(1)
    }

    // 音频 测温区域
    iOffset = header + params.iheight * params.iwidth * 3 + params.lightBuffer.length
    let iAudioLen = 0
    let roiFlag = false
    if (irgU8Array.length > iOffset) {
      for (let m = iOffset; m < irgU8Array.length - 4; m++) {
        if (irgU8Array[m] === 0xab && irgU8Array[m + 1] === 0xcd && irgU8Array[m + 2] === 0xef && irgU8Array[m + 3] === 0xff) {
          roiFlag = true
          break
        }
        iAudioLen++
      }
    }

    if (roiFlag) {
      // 有测温区域
      if (iAudioLen) {
        // 有音频和测温区域

        // 音频结束下标
        const audioEnd = iOffset + iAudioLen
        params.pAudioBuffer = irgU8Array.slice(iOffset, audioEnd)

        // 测温区域
        roiBuffer = irgU8Array.slice(audioEnd)
      } else {
        // 只有测温区域
        roiBuffer = irgU8Array.slice(iOffset)
      }
    } else {
      // 只有音频
      params.pAudioBuffer = irgU8Array.slice(iOffset)
    }
  }

  // 解析测温区域
  // 前四个字节AB\CD\EF\FF
  // 点个数
  // 线个数
  // 矩形个数
  // 圆形个数
  // 多边形个数
  // 4*点个数+ff+ff
  // 4*点个数+ff+ff+8*线个数+ff+ff
  // 4*点个数+ff+ff+8*线个数+ff+ff+8*矩形个数+ff+ff
  // 4*点个数+ff+ff+8*线个数+ff+ff+8*矩形个数+ff+ff++6*圆形个数+ff+ff
  // 4*点个数+ff+ff+8*线个数+ff+ff+8*矩形个数+ff+ff++6*圆形个数+ff+ff+ff+多边形点个数+多边形点个数*4+ff+ff
  // ....
  // +ff+ef+cd+ab
  // 共1024字节, 不够补0
  if (roiBuffer.length > 0) {
    const roiTypeAndInfo = {} // key String 区域类别 val [] 坐标信息
    const pointNum = roiBuffer[4]
    const lineNum = roiBuffer[5]
    const rectangleNum = roiBuffer[6]
    const circleNum = roiBuffer[7]
    const polygonNum = roiBuffer[8]

    let roiTypeBuffer = []
    // 点
    let offset = 9
    if (pointNum) {
      roiTypeBuffer = roiBuffer.slice(offset, offset + pointNum * 4)
      const list = []
      for (let i = 0; i < roiTypeBuffer.length; i = i + 4) {
        list.push({ x: jsRead(roiTypeBuffer, i, 2), y: jsRead(roiTypeBuffer, i + 2, 2) })
      }
      roiTypeAndInfo['point'] = list
    }

    // 线
    offset += pointNum * 4 + 2
    if (lineNum) {
      roiTypeBuffer = roiBuffer.slice(offset, offset + lineNum * 8)
      const list = []
      for (let i = 0; i < roiTypeBuffer.length; i = i + 8) {
        list.push({
          startX: jsRead(roiTypeBuffer, i, 2),
          startY: jsRead(roiTypeBuffer, i + 2, 2),
          endX: jsRead(roiTypeBuffer, i + 4, 2),
          endY: jsRead(roiTypeBuffer, i + 6, 2)
        })
      }
      roiTypeAndInfo['line'] = list
    }

    // 矩形
    offset += lineNum * 8 + 2
    if (rectangleNum) {
      roiTypeBuffer = roiBuffer.slice(offset, offset + rectangleNum * 8)
      const list = []
      for (let i = 0; i < roiTypeBuffer.length; i = i + 8) {
        list.push({
          startX: jsRead(roiTypeBuffer, i, 2),
          startY: jsRead(roiTypeBuffer, i + 2, 2),
          endX: jsRead(roiTypeBuffer, i + 4, 2),
          endY: jsRead(roiTypeBuffer, i + 6, 2)
        })
      }
      roiTypeAndInfo['rectangle'] = list
    }

    // 圆形
    offset += rectangleNum * 8 + 2
    if (circleNum) {
      roiTypeBuffer = roiBuffer.slice(offset, offset + circleNum * 6)
      const list = []
      for (let i = 0; i < roiTypeBuffer.length; i = i + 6) {
        list.push({ x: jsRead(roiTypeBuffer, i, 2), y: jsRead(roiTypeBuffer, i + 2, 2), r: jsRead(roiTypeBuffer, i + 4, 2) })
      }
      roiTypeAndInfo['circle'] = list
    }

    // 多边形
    offset += circleNum * 6 + 2
    if (polygonNum) {
      roiTypeAndInfo['polygon'] = []
      // 依次取点个数 和 点
      let polyPointNum = 0
      let endFlag = false

      do {
        offset += 1
        polyPointNum = roiBuffer[offset]
        offset += 1
        const pointList = []
        for (let i = offset; i < offset + polyPointNum * 4; i = i + 4) {
          pointList.push({ x: jsRead(roiBuffer, i, 2), y: jsRead(roiBuffer, i + 2, 2) })
        }
        roiTypeAndInfo['polygon'].push(pointList)
        offset += polyPointNum * 4

        // 判断是否结束
        if (!roiBuffer[offset + 1] || roiBuffer[offset + 1] === 0xff) {
          endFlag = true
        }
      } while (!endFlag)
    }

    params.roiTypeAndInfo = roiTypeAndInfo
  }

  return params
}

// 解析PX1
function getParamsByPX1(u8Array, params) {
  console.log('PX1')
  // 红外数据的起始偏移地址
  const endNum = u8Array.length - 16
  const iOffset = jsRead(u8Array, endNum - 4, 4)
  // 宽度
  params.iwidth = jsRead(u8Array, iOffset + 2, 2)
  params.irWidth = params.iwidth
  // 高度
  params.iheight = jsRead(u8Array, iOffset + 4, 2)
  params.irHeight = params.iheight
  // 红外温度值点阵数据长度
  const tempLength = params.iwidth * params.iheight * 4
  // 发射率
  params.fEmiss = hex2float(jsRead(u8Array, iOffset + tempLength + 20, 4))
  // 大气温度
  params.fAirTemp = hex2float(jsRead(u8Array, iOffset + tempLength + 20 + 4, 4)) + 273.15
  // 反射温度
  params.fReflectTemp = hex2float(jsRead(u8Array, iOffset + tempLength + 20 + 14, 4)) + 273.15
  // 距离
  params.fDistance = jsRead(u8Array, iOffset + tempLength + 20 + 9, 4)
  // 产品型号
  params.gwType = 'PX1'
  // 设置默认值
  // 大气透过率  默认1
  params.fHumidity = 1
  // 距离修正系数K0
  params.k0 = 0
  // 距离修正系数B0
  params.b0 = 10000
  // 距离修正系数K1
  params.k1 = 0
  // 距离修正系数B1
  params.b1 = 0

  params.sSerialName = 'PX1'

  // 温度
  const pTempBuffer = []
  // 红外数据的起始偏移地址
  for (let i = iOffset + 20; i < iOffset + 20 + tempLength; i++) {
    pTempBuffer.push(u8Array[i])
  }

  if (pTempBuffer.length > 0) {
    let maxTemp = 0
    let minTemp = 0
    const pTempBufferDst = []
    // 4合一 转32进制  摄氏度
    for (let i = 0; i < pTempBuffer.length; i += 4) {
      const temp = (hex2float(jsRead(pTempBuffer, i, 4)) + 273.15) * 10 // 为防止失精度将摄氏度转为开尔文
      pTempBufferDst.push(temp)

      if (i === 0) {
        maxTemp = temp
        minTemp = temp
      } else if (temp > maxTemp) {
        maxTemp = temp
      } else if (temp < minTemp) {
        minTemp = temp
      }
    }
    params.pTempBufferDst = pTempBufferDst
    params.maxTemp = (maxTemp / 10 - 273.15).toFixed(1)
    params.minTemp = (minTemp / 10 - 273.15).toFixed(1)

    // 红外
    // 灰度图
    params.mIRBuffer = []
    for (let i = 0; i < pTempBufferDst.length; i++) {
      params.mIRBuffer.push(parseInt((255 * (pTempBufferDst[i] - minTemp)) / (maxTemp - minTemp)))
    }
  }
}

// 转float
function hex2float(num) {
  //符号位
  var sign = num & 0x80000000 ? -1 : 1
  //指数位
  var exponent = ((num >> 23) & 0xff) - 127
  //尾数位
  var mantissa = 1 + (num & 0x7fffff) / 0x7fffff
  return sign * mantissa * Math.pow(2, exponent)
}

// 读取字节
// u8Array 待读取数组
// startIndex 起始位置
// readNum 读取长度
function jsRead(u8Array, startIndex, readNum) {
  const list = []
  for (let i = 0; i < readNum; i++) {
    list.push(u8Array[startIndex + i])
  }

  return uint8Touint32(list)
}

// 转换产品名称
function translateProName(cProType) {
  if (cProType === 'BAAB' || cProType === 'CAAC') {
    return 'C Series'
  } else if (cProType === 'ADDA') {
    return 'M Series'
  } else if (cProType === 'EAAE') {
    return 'P Series'
  } else if (cProType === 'AFFA') {
    return 'PX Series'
  } else if (cProType === 'A00A') {
    return 'T Series'
  } else if (cProType === 'A11A') {
    return 'S Series'
  } else if (cProType === 'A22A' || cProType === 'A33A') {
    return 'GAS Series'
  } else {
    return 'C Series'
  }
}

// uint8转uint32
function uint8Touint32(value) {
  const a = new Uint32Array(1)

  for (let i = value.length - 1; i >= 0; i--) {
    a[0] += (value[i] & 0xff) << (8 * i)
  }

  return a[0]
}

/**
 * 点取温度
 * @param {*} iX1 x
 * @param {*} iY1 y
 * @param {*} m_TempBuffer 温度数组
 * @param {*} m_proType 产品型号
 * @param {*} m_iWidth 宽度
 */
export function getPointTemp(iX1, iY1, m_TempBuffer, m_proType, m_iWidth) {
  let iIndex = iY1 * m_iWidth + iX1
  let iTemp = m_TempBuffer[iIndex]
  let fTemp = 0

  fTemp = translateKTempUnit(0, iTemp / 10.0)

  const cResult = {}
  cResult.avg = fTemp.toFixed(2)
  cResult.max = fTemp.toFixed(2)
  cResult.min = fTemp.toFixed(2)

  return cResult
}

/**
 * 多边形取温度
 * @param {*} polyXYList 顶点坐标列表
 * @param {*} fZoom 缩放倍数
 * @param {*} m_TempBuffer 温度数组
 * @param {*} m_proType 产品型号
 * @param {*} m_iWidth 宽度
 * @param {*} m_iHeight 长度
 *
 */
export function getPolyTemp(polyXYList, m_TempBuffer, m_proType, m_iWidth, m_iHeight, fZoom = 1) {
  let bIfFirst = true
  let iTemp = 0
  let fTemp = 0
  // let iCenterX = 0;
  // let iCenterY = 0;
  let iCount = 1
  let fTotal = 0
  // let iX_Min = 0, iY_Min = 0;
  // let iX_Max = 0, iY_Max = 0;
  let fMin = 0.0
  let fMax = 0.0
  let iMaxY = 0,
    iMaxX = 0,
    iMinX = 0,
    iMinY = 0
  let iIndex = 0
  try {
    iMaxY = iMinY = polyXYList[0].y
    iMaxX = iMinX = polyXYList[0].x
    for (let j = 1; j < polyXYList.length; j++) {
      iMaxX = polyXYList[j].x > iMaxX ? polyXYList[j].x : iMaxX
      iMinX = polyXYList[j].x < iMinX ? polyXYList[j].x : iMinX
      iMaxY = polyXYList[j].y > iMaxY ? polyXYList[j].y : iMaxY
      iMinY = polyXYList[j].y < iMinY ? polyXYList[j].y : iMinY
    }

    iCount = 0
    fTotal = 0
    bIfFirst = true
    if (iMinY < 0 || iMinX < 0) {
      return
    }

    if (parseInt(iMaxY / fZoom) >= m_iHeight) {
      return
    }

    const tempList = []
    let maxX = 0
    let maxY = 0
    let minX = 0
    let minY = 0
    for (let n = iMinY; n <= iMaxY; n++) {
      for (let m = iMinX; m <= iMaxX; m++) {
        if (pnpoly(polyXYList, m, n)) {
          iIndex = parseInt(n / fZoom) * m_iWidth + parseInt(m / fZoom)
          if (iIndex < 0 || iIndex > m_iWidth * m_iHeight - 1) {
            continue
          }

          iTemp = m_TempBuffer[iIndex]

          fTemp = translateKTempUnit(0, iTemp / 10.0)

          tempList.push(fTemp)

          iCount++
          fTotal += fTemp
          if (bIfFirst) {
            bIfFirst = false
            fMin = fTemp
            fMax = fTemp
            maxX = m
            maxY = n
            minX = m
            minY = n
          } else {
            if (fTemp < fMin) {
              fMin = fTemp
              maxX = m
              maxY = n
              // minX = m
              // minY = n
            }
            if (fTemp > fMax) {
              fMax = fTemp
              minX = m
              minY = n
              // maxX = m
              // maxY = n
            }
          }
        }
      }
    }

    const cResult = {
      avg: (fTotal / iCount).toFixed(2),
      max: fMax.toFixed(2),
      min: fMin.toFixed(2),
      tempList,
      maxX,
      maxY,
      minX,
      minY
    }

    return cResult
  } catch (err) {
    console.log('err => ', err)
  }
}

function pnpoly(polyXYList, testx, testy) {
  let nCount = polyXYList.length
  let nCross = 0,
    i
  let x
  let p1, p2

  for (i = 0; i < nCount; i++) {
    p1 = polyXYList[i]
    p2 = polyXYList[(i + 1) % nCount]
    // 求解 y=p.y 与 p1p2 的交点
    if (p1.y == p2.y)
      // p1p2 与 y=p.y平行
      continue
    if (testy < Math.min(p1.y, p2.y))
      // 交点在p1p2延长线上
      continue
    if (testy >= Math.max(p1.y, p2.y))
      // 交点在p1p2延长线上
      continue
    // 求交点的 X 坐标 --------------------------------------------------------------
    x = ((testy - p1.y) * (p2.x - p1.x)) / (p2.y - p1.y) + p1.x
    if (x > testx) {
      nCross++ // 只统计单边交点
    }
  }
  // 单边交点为偶数，点在多边形之外 ---
  return nCross % 2 == 1
}

/**
 * 圆形取温度
 * @param {*} iCenterX 圆心x
 * @param {*} iCenterY 圆心y
 * @param {*} i_radicus 半径
 * @param {*} fZoom 缩放倍数
 * @param {*} m_TempBuffer 温度数组
 * @param {*} m_proType 产品型号
 * @param {*} m_iWidth 宽度
 */
export function getCircleTemp(iCenterX, iCenterY, i_radicus, m_TempBuffer, m_proType, m_iWidth, fZoom = 1) {
  let iTemp = 0
  let fTemp = 0
  let fMin = 0.0
  let fMax = 0.0
  let bIfFirst = true
  let iLeft = 0,
    iRight = 0,
    iTop = 0,
    iBottom = 0
  let fDistance = 0
  let fTotal = 0
  // let iX_Min = 0,
  //     iY_Min = 0
  // let iX_Max = 0,
  //     iY_Max = 0
  let iCount = 0
  // let iIndex = 0

  try {
    iLeft = iCenterX - i_radicus
    iRight = iCenterX + i_radicus
    iTop = iCenterY - i_radicus
    iBottom = iCenterY + i_radicus

    const tempList = []
    let maxX = 0
    let maxY = 0
    let minX = 0
    let minY = 0
    for (let n = iTop; n <= iBottom; n++) {
      for (let m = iLeft; m <= iRight; m++) {
        fDistance = Math.sqrt(Math.pow(m - iCenterX, 2) + Math.pow(n - iCenterY, 2))
        if (fDistance - i_radicus > 0) {
          continue
        } else {
          iCount++

          iTemp = m_TempBuffer[parseInt(n / fZoom) * m_iWidth + parseInt(m / fZoom)]

          if (iTemp) {
            fTemp = translateKTempUnit(0, iTemp / 10.0)

            tempList.push(fTemp)

            fTotal += fTemp
            if (bIfFirst) {
              bIfFirst = false
              fMin = fTemp
              fMax = fTemp
              maxX = m
              maxY = n
              minX = m
              minY = n
            } else {
              if (fTemp < fMin) {
                fMin = fTemp
                maxX = m
                maxY = n
                // minX = m
                // minY = n
              }
              if (fTemp > fMax) {
                fMax = fTemp
                minX = m
                minY = n
                // maxX = m
                // maxY = n
              }
            }
          }
        }
      }
    }

    const cResult = {
      avg: (fTotal / iCount).toFixed(2),
      max: fMax.toFixed(2),
      min: fMin.toFixed(2),
      tempList,
      maxX,
      maxY,
      minX,
      minY
    }

    return cResult
  } catch (err) {
    console.log('err => ', err)
  }
}

/**
 * 矩形取温度
 * @param {*} iX_start 起点x
 * @param {*} iY_start 起点y
 * @param {*} iX_end 终点x
 * @param {*} iY_end 终点y
 * @param {*} fZoom 缩放倍数
 * @param {*} m_TempBuffer 温度数组
 * @param {*} m_proType 产品型号
 * @param {*} m_iWidth 宽度
 * @param {*} m_iHeight 长度
 */
export function getRctTemp(iX_start, iY_start, iX_end, iY_end, m_TempBuffer, m_proType, m_iWidth, m_iHeight, fZoom = 1) {
  // let iWth = 0;
  // let iHth = 0;
  let iTemp = 0
  let iCount = 0
  let iStartX = 0
  let iStartY = 0
  let iEndX = 0
  let iEndY = 0
  let iIndex = 0
  let fTemp = 0
  let fTotal = 0
  let bIfFirst = true
  let fMin = 0.0
  let fMax = 0.0
  // iWth = Math.abs(iX_end - iX_start)
  // iHth = Math.abs(iY_end - iY_start)

  if (iX_start > iX_end) {
    iStartX = iX_end
    iEndX = iX_start
  } else {
    iEndX = iX_end
    iStartX = iX_start
  }

  if (iY_start > iY_end) {
    iStartY = iY_end
    iEndY = iY_start
  } else {
    iEndY = iY_end
    iStartY = iY_start
  }

  const tempList = []
  let maxX = 0
  let maxY = 0
  let minX = 0
  let minY = 0
  for (let h = iStartY; h < iEndY; h++) {
    for (let w = iStartX; w < iEndX; w++) {
      iCount++
      iIndex = parseInt(h / fZoom) * m_iWidth + parseInt(w / fZoom)
      if (iIndex < 0 || iIndex > m_iWidth * m_iHeight - 1) {
        continue
      }
      iTemp = m_TempBuffer[iIndex]

      fTemp = translateKTempUnit(0, iTemp / 10.0)

      tempList.push(fTemp)

      fTotal += fTemp
      if (bIfFirst) {
        bIfFirst = false
        fMin = fTemp
        fMax = fTemp
        maxX = w
        maxY = h
        minX = w
        minY = h
      } else {
        if (fTemp < fMin) {
          fMin = fTemp
          maxX = w
          maxY = h
          // minX = w
          // minY = h
        }
        if (fTemp > fMax) {
          fMax = fTemp
          minX = w
          minY = h
          // maxX = w
          // maxY = h
        }
      }
    }
  }

  const cResult = {
    avg: (fTotal / iCount).toFixed(2),
    max: fMax.toFixed(2),
    min: fMin.toFixed(2),
    tempList,
    maxX,
    maxY,
    minX,
    minY
  }

  return cResult
}

/**
 * 直线取温度
 * @param {*} iX_start 起点x
 * @param {*} iY_start 起点y
 * @param {*} iX_end 终点x
 * @param {*} iY_end 终点y
 * @param {*} fZoom 缩放倍数
 * @param {*} m_TempBuffer 温度数组
 * @param {*} m_proType 产品型号
 * @param {*} m_iWidth 宽度
 * @param {*} m_iHeight 长度
 */
export function getLineTemp(iX_start, iY_start, iX_end, iY_end, m_TempBuffer, m_proType, m_iWidth, m_iHeight, fZoom = 1) {
  let fMin = 0.0
  let fMax = 0.0
  let fTotal = 0.0
  // let iX_Min = 0
  // let iY_Min = 0
  // let iX_Max = 0
  // let iY_Max = 0
  let k = 0.0
  let b = 0.0
  let bFirst = true
  let iTemp = 0
  let fTemp = 0.0
  // let iCountT = 0

  const iX_center = parseFloat(iX_start + iX_end) / 2
  const iY_center = parseFloat(iY_start + iY_end) / 2

  let iXstart = 0
  let iYstart = 0
  let iXend = 0
  let iYend = 0
  if (iX_start == iX_end) {
    if (iY_start < iY_end) {
      iXstart = parseFloat(iX_start) / fZoom
      iYstart = parseFloat(iY_start) / fZoom
      iXend = parseFloat(iX_end) / fZoom
      iYend = parseFloat(iY_end) / fZoom
    } else if (iY_start > iY_end) {
      iXstart = parseFloat(iX_end) / fZoom
      iYstart = parseFloat(iY_end) / fZoom
      iXend = parseFloat(iX_start) / fZoom
      iYend = parseFloat(iY_start) / fZoom
    }
  } else if (iX_start < iX_end) {
    iXstart = parseFloat(iX_start) / fZoom
    iYstart = parseFloat(iY_start) / fZoom
    iXend = parseFloat(iX_end) / fZoom
    iYend = parseFloat(iY_end) / fZoom
  } else {
    iXstart = parseFloat(iX_end) / fZoom
    iYstart = parseFloat(iY_end) / fZoom
    iXend = parseFloat(iX_start) / fZoom
    iYend = parseFloat(iY_start) / fZoom
  }

  const iPointVec = []
  const cTempPoint = {}
  if (iXend - iXstart != 0 && iYend - iYstart != 0) {
    k = ((iYend - iYstart) * 1.0) / (iXend - iXstart) //k=(y1-y2)/(x1-x2) ;   y=kx+b
    b = parseFloat(iYstart) - k * iXstart

    let i = 0
    let j = 0

    if (iYend < iYstart) {
      //左下 右上
      for (i = iXstart; i <= iXend; i++) {
        if (i == iXend) {
          cTempPoint.y = i * k + b
          cTempPoint.x = i
          iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
          break
        }
        let a = i * k + b
        let c = (i + 1) * k + b //四舍五入
        if (a == c) {
          cTempPoint.y = a
          cTempPoint.x = i
          iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
        } else {
          for (j = a; j > c; j--) {
            cTempPoint.y = j
            cTempPoint.x = (j - b) / k //四舍五入
            iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
          }
        }
      }
    } //左上 右下
    else {
      for (i = iXstart; i <= iXend; i++) {
        if (i == iXend) {
          cTempPoint.y = i * k + b
          cTempPoint.x = i
          iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
          break
        }
        let a = i * k + b
        let c = (i + 1) * k + b
        if (a == c) {
          cTempPoint.y = a
          cTempPoint.x = i
          iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
        } else {
          for (j = a; j < c; j++) {
            cTempPoint.y = j
            cTempPoint.x = (j - b) / k
            iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
          }
        }
      }
    }
  } else if (iXend - iXstart == 0 && iYend - iYstart != 0) {
    if (iYstart < iYend) {
      //从下到上
      for (let i = iYstart; i < iYend; i++) {
        cTempPoint.y = i
        cTempPoint.x = iXstart
        iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
      }
    } else {
      for (let i = iYend; i <= iYstart; i++) {
        cTempPoint.y = i
        cTempPoint.x = iXstart
        iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
      }
    }
  } else if (iXend - iXstart != 0 && iYend - iYstart == 0) {
    k = 0 //k=(y1-y2)/(x1-x2) ;   y=kx+b
    b = parseFloat(iYstart)
    for (let i = iXstart; i <= iXend; i++) {
      cTempPoint.y = iYend
      cTempPoint.x = i
      iPointVec.push(JSON.parse(JSON.stringify(cTempPoint)))
    }
  } else {
    return
  }

  const tempList = []
  let maxX = 0
  let maxY = 0
  let minX = 0
  let minY = 0

  let iIndex = 0
  for (let m = 0; m < iPointVec.length; m++) {
    //y= k*iX_vec.at(i) + b;     //y=kx+b
    if (iPointVec[m].y < 0 || iPointVec[m].x < 0) {
      return
    }
    iIndex = parseInt(iPointVec[m].y) * m_iWidth + parseInt(iPointVec[m].x)
    if (iIndex < 0 || iIndex > m_iWidth * m_iHeight - 1) {
      continue
    }
    iTemp = m_TempBuffer[iIndex]

    fTemp = translateKTempUnit(0, iTemp / 10.0) //默认存摄氏度

    tempList.push(fTemp)

    fTotal += fTemp
    if (bFirst) {
      bFirst = false
      fMin = fTemp
      fMax = fTemp
      maxX = iPointVec[m].x
      maxY = iPointVec[m].y
      minX = iPointVec[m].x
      minY = iPointVec[m].y
    } else {
      if (fTemp < fMin) {
        fMin = fTemp
        maxX = iPointVec[m].x
        maxY = iPointVec[m].y
        // minX = iPointVec[m].x
        // minY = iPointVec[m].y
      }
      if (fTemp > fMax) {
        fMax = fTemp
        minX = iPointVec[m].x
        minY = iPointVec[m].y
        // maxX = iPointVec[m].x
        // maxY = iPointVec[m].y
      }
    }
  }

  try {
    iIndex = parseInt(iY_center / fZoom) * m_iWidth + parseInt(iX_center / fZoom)
    if (iIndex < 0 || iIndex > m_iWidth * m_iHeight - 1) {
      return
    }

    iTemp = parseInt(m_TempBuffer[iIndex])
  } catch (err) {
    console.log('err => ', err)
    return
  }

  fTemp = translateKTempUnit(0, iTemp / 10.0)

  const cResult = {
    avg: (fTotal / iPointVec.length).toFixed(2),
    max: fMax.toFixed(2),
    min: fMin.toFixed(2),
    tempList,
    maxX,
    maxY,
    minX,
    minY
  }

  return cResult
}

function translateKTempUnit(iType, fTemp) {
  //K转为目标温度单位
  let fValue = 0
  switch (iType) {
    case 0:
      fValue = translateKtoC(fTemp)
      break
    case 1:
      fValue = fTemp
      break
    case 2:
      fValue = translateKtoF(fTemp)
      break
  }
  return fValue
}

function translateKtoF(fK) {
  return ((fK - 273.15) * 9.0) / 5.0 + 32
}

function translateKtoC(fK) {
  return fK - 273.15
}
